import Vue from "vue";
import { STORE_SELECT_STORES } from "@/graphql/store/queries.gql";

/**
 * Define current view as store view to get access to `storeId` prop, `currentStoreNumber` computed and `storeNumbers` computed map
 * @returns extendable Vue instance
 */
export function useStoreView() {
  return Vue.extend({
    name: "StoreView",
    props: {
      storeId: {
        type: [Number, String],
        required: true,
      },
    },
    data: () => ({
      stores: [],
    }),
    apollo: {
      stores: {
        query: STORE_SELECT_STORES,
      },
    },
    computed: {
      currentStoreNumber() {
        return this.storeNumbers.get(this.storeId) || "";
      },
      storeNumbers() {
        return new Map(this.stores.map((x) => [x.id, x.storeNumber]));
      },
    },
  });
}
